import {
  SET_ACHIEVEMENTS_DATA,
  SET_ACHIEVEMENTS_STATE,
  UPDATE_ACHIEVEMENTS_DATA,
  RECEIVED_ACHIEVEMT,
} from '../actionTypes';

export const setAchievementsData = (payload) => ({
  type: SET_ACHIEVEMENTS_DATA,
  payload,
});

export const setAchievementsState = (payload) => ({
  type: SET_ACHIEVEMENTS_STATE,
  payload,
});

export const updateAchievements = (payload) => ({
  type: UPDATE_ACHIEVEMENTS_DATA,
  payload,
});

export const setReceiveAchievement = (payload) => ({
  type: RECEIVED_ACHIEVEMT,
  payload,
});
