import { proxyHandler } from 'src/utils/redux';

const prefix = '@depositHistory';

const constants = {
  SET_DATA: 'setData',
  UPDATE_META: 'updateMeta',
  SET_STATUS: 'setStatus',
  SET_ERROR: 'setError',
  SET_FIRST_LOAD: 'setFirstLoad',
};

export const DEPOSIT_HISTORY = new Proxy(constants, proxyHandler(prefix));
