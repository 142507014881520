import React, { PureComponent, Fragment } from 'react';
import * as Sentry from '@sentry/react';

class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    if (process.env.REACT_APP_PRODUCTION === 'TRUE') {
      Sentry.captureException(error);
      window.location.reload();
    }
  }

  render() {
    if (this.state.hasError) {
      // window.location.reload()
    }
    return <React.Fragment>{this.state.hasError ? <div /> : this.props.children}</React.Fragment>;
  }
}

export default ErrorBoundary;
