import { proxyHandler } from 'src/utils/redux';

const prefix = '@deposit';

const constants = {
  SET_SAVED_CARDS_STATUS: 'setSavedCardsLoading',
  SET_SAVED_CARDS_DATA: 'setSavedCardsData',
  SET_SAVED_CARDS_DISABLED: 'setSavedCardsDisabled',
  SET_SAVED_CARDS_ERROR: 'setSavedCardsError',
  FILTER_SAVED_CARDS: 'filterSavedCards',
};

export const DEPOSIT_CONSTANTS = new Proxy(constants, proxyHandler(prefix));
