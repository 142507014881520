export const getBearer = () => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  if (!userData || !apiToken) {
    return {};
  }

  return {
    Authorization: `Bearer ${apiToken}`,
  };
};
