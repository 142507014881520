import { proxyHandler } from 'src/utils/redux';

const prefix = '@withdraw';

const constants = {
  SET_TOTAL_BALANCE: 'setTotalBalance',
  SET_AVAILABLE: 'setAvailable',
  SET_TOTAL_WINNINGS: 'setTotalWinnings',
  SET_TOTAL_WINNINGS_VOTES: 'setTotalWinningsVotes',
  SET_DATA: 'setData',
  UPDATE_META: 'updateMeta',
  SET_STATUS: 'setStatus',
  SET_IS_FIRST_LOAD: 'setIsFirstLoad',
  SET_ERROR: 'setError',
};

export const WITHDRAW_CONSTANTS = new Proxy(constants, proxyHandler(prefix));
