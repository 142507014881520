import { LOADING_STATES } from 'src/utils/maps';
import { DEPOSIT_HISTORY } from 'src/constants/depositHistory';
import { newState } from 'src/utils';
import { TRANSACTION_HISTORY } from 'src/constants/transactionHistory';
import { WITHDRAWAL_HISTORY } from 'src/constants/withdrawalHistory';

const initialState = {
  depositHistory: {
    data: [],
    meta: {
      total: 0,
      page: 1,
      perPage: 10,
      next: null,
    },
    totalVotes: 0,
    status: LOADING_STATES.LOADING,
    error: null,
    isFirstLoad: true,
  },
  transactionHistory: {
    data: [],
    meta: {
      total: 0,
      page: 0,
      perPage: 10,
      next: null,
    },
    status: LOADING_STATES.LOADING,
    error: null,
    isFirstLoad: true,
  },
  withdrawalHistory: {
    data: [],
    meta: {
      total: 0,
      page: 0,
      perPage: 10,
      next: null,
    },
    status: LOADING_STATES.LOADING,
    error: null,
    isFirstLoad: true,
  },
};

const paymentsHistory = (state = initialState, action) => {
  switch (action.type) {
    case DEPOSIT_HISTORY.SET_DATA:
      return newState(state, {
        depositHistory: {
          ...state.depositHistory,
          data: action.payload,
        },
      });
    case DEPOSIT_HISTORY.UPDATE_META:
      return newState(state, {
        depositHistory: {
          ...state.depositHistory,
          meta: {
            ...state.depositHistory.meta,
            ...action.payload,
          },
        },
      });
    case DEPOSIT_HISTORY.SET_STATUS:
      return newState(state, {
        depositHistory: {
          ...state.depositHistory,
          status: action.payload,
        },
      });
    case DEPOSIT_HISTORY.SET_ERROR:
      return newState(state, {
        depositHistory: {
          ...state.depositHistory,
          error: action.payload,
        },
      });
    case DEPOSIT_HISTORY.SET_FIRST_LOAD:
      return newState(state, {
        depositHistory: {
          ...state.depositHistory,
          isFirstLoad: action.payload,
        },
      });
    case TRANSACTION_HISTORY.SET_DATA:
      return newState(state, {
        transactionHistory: {
          ...state.transactionHistory,
          data: action.payload,
        },
      });
    case TRANSACTION_HISTORY.UPDATE_META:
      return newState(state, {
        transactionHistory: {
          ...state.transactionHistory,
          meta: {
            ...state.transactionHistory.meta,
            ...action.payload,
          },
        },
      });
    case TRANSACTION_HISTORY.SET_STATUS:
      return newState(state, {
        transactionHistory: {
          ...state.transactionHistory,
          status: action.payload,
        },
      });
    case TRANSACTION_HISTORY.SET_ERROR:
      return newState(state, {
        transactionHistory: {
          ...state.transactionHistory,
          error: action.payload,
        },
      });
    case TRANSACTION_HISTORY.SET_FIRST_LOAD:
      return newState(state, {
        transactionHistory: {
          ...state.transactionHistory,
          isFirstLoad: action.payload,
        },
      });
    case WITHDRAWAL_HISTORY.SET_DATA:
      return newState(state, {
        withdrawalHistory: {
          ...state.withdrawalHistory,
          data: action.payload,
        },
      });
    case WITHDRAWAL_HISTORY.UPDATE_META:
      return newState(state, {
        withdrawalHistory: {
          ...state.withdrawalHistory,
          meta: {
            ...state.withdrawalHistory.meta,
            ...action.payload,
          },
        },
      });
    case WITHDRAWAL_HISTORY.SET_STATUS:
      return newState(state, {
        withdrawalHistory: {
          ...state.withdrawalHistory,
          status: action.payload,
        },
      });
    case WITHDRAWAL_HISTORY.SET_ERROR:
      return newState(state, {
        withdrawalHistory: {
          ...state.withdrawalHistory,
          error: action.payload,
        },
      });
    case WITHDRAWAL_HISTORY.SET_FIRST_LOAD:
      return newState(state, {
        withdrawalHistory: {
          ...state.withdrawalHistory,
          isFirstLoad: action.payload,
        },
      });
    default:
      return state;
  }
};

export default paymentsHistory;
