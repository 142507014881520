export const LOADING_STATES = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  LOADED: 'LOADED',
  IDLE: 'IDLE',
  INITIAL_LOADING: 'INITIAL_LOADING',
};

export const PAYMENT_STATES = {
  IN_PROCESS: 'in-process',
  CANCEL: 'cancel',
  DECLINE: 'decline',
  SUCCESS: 'success',
};
