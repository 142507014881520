import { proxyHandler } from 'src/utils/redux';

const prefix = '@challenge';

const constants = {
  SET_CHALLENGE_INFO_DATA: 'setInfoData',
  SET_CHALLENGE_INFO_STATUS: 'setInfoStatus',
  SET_CHALLENGE_INFO_ERROR: 'setInfoError',
  SET_CHALLENGE_LEADERBOARD_DATA: 'setLeaderboardData',
  SET_CHALLENGE_LEADERBOARD_PAGE: 'setLeaderboardPage',
  SET_CHALLENGE_LEADERBOARD_STATUS: 'setLeaderboardStatus',
  SET_CHALLENGE_LEADERBOARD_IS_MORE: 'setLeaderboardIsMore',
  SET_CHALLENGE_LEADERBOARD_ERROR: 'setLeaderboardError',
  SET_CHALLENGE_LEADERBOARD_IS_LOADED: 'setLeaderboardIsLoaded',
  UPDATE_CHALLENGE_LEADERBOARD_ITEM: 'updateLeaderboardItem',
  RESET_CHALLENGE_LEADERBOARD: 'resetLeaderboard',

  RESET_CHALLENGE_PAGE: 'reset',

  SET_CHALLENGE_PHOTO_DATA: 'setPhotoData',
  SET_CHALLENGE_PHOTO_STATUS: 'setPhotoStatus',
  SET_CHALLENGE_PHOTO_ERROR: 'setPhotoError',
  RESET_CHALLENGE_PHOTO: 'resetPhoto',

  SET_CHALLENGE_PACKAGES_DATA: 'setPackagesData',
  SET_CHALLENGE_PACKAGES_STATUS: 'setPackagesStatus',
  SET_CHALLENGE_PACKAGES_ERROR: 'setPackagesError',
  RESET_CHALLENGE_PACKAGES: 'resetPackages',

  SET_CHALLENGE_VOTE_INFO: 'setVoteInfo',
  RESET_CHALLENGE_VOTE_INFO: 'resetVoteInfo',

  SET_MY_PHOTOS_DATA: 'setMyPhotosData',
  SET_MY_PHOTOS_STATUS: 'setMyPhotosStatus',
  SET_MY_PHOTOS_ERROR: 'setMyPhotosError',

  SET_REPLACE_PLACEHOLDER_INFO: 'setReplacePlaceholderInfo',
  SET_SCROLLING_ITEM: 'setScrollingToItem',
};

const CHALLENGE_CONSTANTS = new Proxy(constants, proxyHandler(prefix));

export default CHALLENGE_CONSTANTS;
