import { LOADING_STATES } from 'src/utils/maps';
import { produce } from 'immer';
import CHALLENGES_CONSTANTS from 'src/modules/challenges/constants/challenges';

const initialState = {
  closed: {
    status: LOADING_STATES.LOADING,
    isLoaded: false,
    isMore: true,
    page: 0,
    data: [],
    error: null,
  },
  active: {
    status: LOADING_STATES.LOADING,
    isLoaded: false,
    isMore: true,
    page: 0,
    data: [],
    error: null,
  },
};

const challengesReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case CHALLENGES_CONSTANTS.SET_CHALLENGES_ACTIVE_STATUS:
      draft.active.status = action.payload;
      break;
    case CHALLENGES_CONSTANTS.SET_CHALLENGES_ACTIVE_IS_LOADED:
      draft.active.isLoaded = action.payload;
      break;
    case CHALLENGES_CONSTANTS.SET_CHALLENGES_ACTIVE_PAGE:
      draft.active.page = action.payload;
      break;
    case CHALLENGES_CONSTANTS.SET_CHALLENGES_ACTIVE_DATA:
      draft.active.data = action.payload;
      break;
    case CHALLENGES_CONSTANTS.SET_CHALLENGES_ACTIVE_ERROR:
      draft.active.error = action.payload;
      break;
    case CHALLENGES_CONSTANTS.SET_CHALLENGES_ACTIVE_MORE:
      draft.active.isMore = action.payload;
      break;
    case CHALLENGES_CONSTANTS.RESET_CHALLENGES_ACTIVE:
      draft.active = initialState.active;
      return draft;
    case CHALLENGES_CONSTANTS.SET_CHALLENGES_CLOSED_STATUS:
      draft.closed.status = action.payload;
      break;
    case CHALLENGES_CONSTANTS.SET_CHALLENGES_CLOSED_IS_LOADED:
      draft.closed.isLoaded = action.payload;
      break;
    case CHALLENGES_CONSTANTS.SET_CHALLENGES_CLOSED_PAGE:
      draft.closed.page = action.payload;
      break;
    case CHALLENGES_CONSTANTS.SET_CHALLENGES_CLOSED_DATA:
      draft.closed.data = action.payload;
      break;
    case CHALLENGES_CONSTANTS.SET_CHALLENGES_CLOSED_ERROR:
      draft.closed.error = action.payload;
      break;
    case CHALLENGES_CONSTANTS.SET_CHALLENGES_CLOSED_MORE:
      draft.closed.isMore = action.payload;
      break;
    case CHALLENGES_CONSTANTS.RESET_CHALLENGES_CLOSED:
      draft.closed = initialState.closed;
      return draft;
    default:
      return state;
  }
});

export default challengesReducer;
