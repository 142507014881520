import React, { memo } from 'react';
import classNames from 'classnames';
import style from './preloader.module.scss';

const Preloader = (props) => (
  <div className={classNames(style.Circle, props.className)}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default memo(Preloader);
