import { proxyHandler } from 'src/utils/redux';

const prefix = '@tournamentGame';

const constants = {
  setData: 'setData',
  updateMeta: 'updateMeta',
  setStatus: 'setStatus',
  setIsMore: 'setIsMore',
  filterById: 'filterById',
  setError: 'error',
  reset: 'reset',
};

export const TOURNAMENT_GAME_CONSTANTS = new Proxy(constants, proxyHandler(prefix));
