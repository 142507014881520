import { proxyHandler } from 'src/utils/redux';

const prefix = '@tournaments';

const constants = {
  SET_DATA: 'setData',
  SET_SELECTED_TOURNAMENT_DATA: 'setSelectedTournamentData',
  SET_SELECTED_TOURNAMENT_STATUS: 'setSelectedTournamentStatus',
  SET_SELECTED_TOURNAMENT_ERROR: 'setSelectedTournamentError',
  UPDATE_META: 'updateMeta',
  SET_STATUS: 'setStatus',
  SET_IS_FIRST_LOAD: 'setIsFirstLoad',
  SET_ERROR: 'setError',
  SET_TOURNAMENTS_TYPE: 'setTournamentsType',
  SET_TOURNAMENTS_LEVEL: 'setTournamentsLevel',
  RESET: 'reset',
  SET_SELECTED_ID: 'setSelectedTournamentId',
  ADD_PARTICIPANT: 'addParticipant',
};

export const TOURNAMENTS_CONSTANTS = new Proxy(constants, proxyHandler(prefix));
