import { proxyHandler } from 'src/utils/redux';

const prefix = '@referral';

const constants = {
  SET_DATA: 'setData',
  // UPDATE_META: 'updateMeta',
  SET_STATUS: 'setStatus',
  // SET_ERROR: 'setError',
  // SET_FIRST_LOAD: 'setFirstLoad',
};

export const REFERRAL_DATA = new Proxy(constants, proxyHandler(prefix));
