import React from 'react';
import { useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { setCountryModalDisabled } from 'src/actions/app';
import s from './DisabledCountryModal.module.scss';
import DisabledCountryContent from './DisabledCountryContent';

const DisabledCountryModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const code = useSelector(({ app }) => app.currentCountry && app.currentCountry.code.toLowerCase());

  return (
    <div className={s.wrapper}>
      <DisabledCountryContent handleClick={() => {
        history.push(`/${code}/challenges/current`);
        dispatch(setCountryModalDisabled(false));
      }}
      />
    </div>
  );
};

export default injectIntl(DisabledCountryModal);
