import { DEPOSIT_CONSTANTS } from 'src/constants/deposit';
import { LOADING_STATES } from 'src/utils/maps';
import { newState } from 'src/utils';

const initialState = {
  data: [],
  status: LOADING_STATES.LOADING,
  disabled: false,
  error: null,
};

const depositReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEPOSIT_CONSTANTS.SET_SAVED_CARDS_DATA:
      return newState(state, {
        data: action.payload,
      });
    case DEPOSIT_CONSTANTS.SET_SAVED_CARDS_STATUS:
      return newState(state, {
        status: action.payload,
      });
    case DEPOSIT_CONSTANTS.SET_SAVED_CARDS_DISABLED:
      return newState(state, {
        disabled: action.payload,
      });
    case DEPOSIT_CONSTANTS.SET_SAVED_CARDS_ERROR:
      return newState(state, {
        error: action.payload,
      });
    case DEPOSIT_CONSTANTS.FILTER_SAVED_CARDS:
      const newCards = state.data.filter(
        (item) => item.fingerprint !== action.payload,
      );

      return newState(state, {
        data: newCards,
      });
    default:
      return state;
  }
};

export default depositReducer;
