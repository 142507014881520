export const proxyHandler = (prefix) => ({
  get(target, name) {
    if (!target[name]) {
      throw new Error(`Unknown action ${name}`);
    }
    return `${prefix}/${target[name]}`;
  },
  set() {
    throw new Error('Cant modify constants outside');
  },
});
