import { LOADING_STATES } from 'src/utils/maps';
import { newState } from 'src/utils';
import { TOURNAMENTS_CONSTANTS } from 'src/modules/tournaments/constants/tournaments';

const initialState = {
  data: [],
  selectedTournament: {
    id: null,
    data: null,
    status: LOADING_STATES.LOADING,
    error: null,
  },
  meta: {
    total: 0,
    page: 0,
    perPage: null,
    next: null,
  },
  status: LOADING_STATES.LOADING,
  isFirstLoad: true,
  error: null,
  tournamentsType: null,
  tournamentsLevel: null,
};

const withdrawReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case TOURNAMENTS_CONSTANTS.SET_DATA:
      return newState(state, {
        data: action.payload,
      });
    case TOURNAMENTS_CONSTANTS.SET_SELECTED_TOURNAMENT_DATA:
      return newState(state, {
        selectedTournament: {
          ...state.selectedTournament,
          data: action.payload,
        },
      });
    case TOURNAMENTS_CONSTANTS.SET_SELECTED_TOURNAMENT_STATUS:
      return newState(state, {
        selectedTournament: {
          ...state.selectedTournament,
          status: action.payload,
        },
      });
    case TOURNAMENTS_CONSTANTS.SET_SELECTED_TOURNAMENT_ERROR:
      return newState(state, {
        selectedTournament: {
          ...state.selectedTournament,
          error: action.payload,
        },
      });
    case TOURNAMENTS_CONSTANTS.UPDATE_META:
      return newState(state, {
        meta: {
          ...state.meta,
          ...action.payload,
        },
      });
    case TOURNAMENTS_CONSTANTS.SET_STATUS:
      return newState(state, {
        status: action.payload,
      });
    case TOURNAMENTS_CONSTANTS.SET_IS_FIRST_LOAD:
      return newState(state, {
        isFirstLoad: action.payload,
      });
    case TOURNAMENTS_CONSTANTS.SET_ERROR:
      return newState(state, {
        error: action.payload,
      });
    case TOURNAMENTS_CONSTANTS.SET_TOURNAMENTS_TYPE:
      return newState(state, {
        tournamentsType: action.payload,
      });
    case TOURNAMENTS_CONSTANTS.SET_TOURNAMENTS_LEVEL:
      return newState(state, {
        tournamentsLevel: action.payload,
      });
    case TOURNAMENTS_CONSTANTS.SET_SELECTED_ID:
      return newState(state, {
        selectedTournament: {
          ...state.selectedTournament,
          id: action.payload,
        },
      });
    case TOURNAMENTS_CONSTANTS.ADD_PARTICIPANT:
      const newParticipants = [...state.selectedTournament.data.participants, action.payload];

      return newState(state, {
        selectedTournament: {
          ...state.selectedTournament,
          data: {
            tournament: {
              ...state.selectedTournament.data.tournament,
              current_participant_count: newParticipants.length,
            },
            participants: newParticipants,
          },
        },
      });
    case TOURNAMENTS_CONSTANTS.RESET:
      return initialState;
    default:
      return state;
  }
};

export default withdrawReducer;
