import { proxyHandler } from 'src/utils/redux';

const prefix = '@challenges';

const constants = {
  SET_CHALLENGES_ACTIVE_STATUS: 'setActiveStatus',
  SET_CHALLENGES_ACTIVE_IS_LOADED: 'setActiveIsLoaded',
  SET_CHALLENGES_ACTIVE_PAGE: 'setActivePage',
  SET_CHALLENGES_ACTIVE_DATA: 'setActiveData',
  SET_CHALLENGES_ACTIVE_ERROR: 'setActiveError',
  SET_CHALLENGES_ACTIVE_MORE: 'setActiveMore',
  RESET_CHALLENGES_ACTIVE: 'resetActive',
  SET_CHALLENGES_CLOSED_STATUS: 'setClosedStatus',
  SET_CHALLENGES_CLOSED_IS_LOADED: 'setClosedIsLoaded',
  SET_CHALLENGES_CLOSED_PAGE: 'setClosedPage',
  SET_CHALLENGES_CLOSED_DATA: 'setClosedData',
  SET_CHALLENGES_CLOSED_ERROR: 'setClosedError',
  SET_CHALLENGES_CLOSED_MORE: 'setClosedMore',
  RESET_CHALLENGES_CLOSED: 'resetClosed',
};

const CHALLENGES_CONSTANTS = new Proxy(constants, proxyHandler(prefix));

export default CHALLENGES_CONSTANTS;
