import { SUBMIT_ACCOUNT, RESETE_ACCOUNT } from '../actionTypes';
import { newState } from '../utils';

const initialState = {
  submitAccount: false,
  userEmail: null,
  userPhone: null,
  userToken: null,
};

const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_ACCOUNT:
      return newState(state, {
        submitAccount: true,
        userEmail: action.payload.userEmail,
        userPhone: action.payload.userPhone,
        userToken: action.payload.userToken,
      });
    case RESETE_ACCOUNT:
      return initialState;
    default:
      return state;
  }
};

export default registrationReducer;
