import React from 'react';
import ReactSVG from 'react-svg';
import SeDisabled from 'src/static/svg/seDisabled.svg';
import NzDisabled from 'src/static/svg/NzDisabled.svg';
import NoDisabled from 'src/static/svg/NoDisabled.svg';
import IeDisabled from 'src/static/svg/IeDisabled.svg';
import FiDisabled from 'src/static/svg/FiDisabled.svg';
import Button from 'src/components/Form/Button';
import { defineMessages, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { COUNTRIES_MAP } from 'src/modules/common/constants/countries';
import style from './DisabledCountryContent.module.scss';

const messages = defineMessages({
  'The {name} contest has been temporarily closed': {
    id: 'The {name} contest has been temporarily closed',
    defaultMessage: 'The {name} contest has been temporarily closed',
  },
  Sweden: {
    id: 'Sweden',
    defaultMessage: 'Sweden',
  },
  Ireland: {
    id: 'Ireland',
    defaultMessage: 'Ireland',
  },
  Finland: {
    id: 'Finland',
    defaultMessage: 'Finland',
  },
  Norway: {
    id: 'Norway',
    defaultMessage: 'Norway',
  },
  'New Zealand': {
    id: 'New Zealand',
    defaultMessage: 'New Zealand',
  },
});

const CODE_CONTENT = {
  se: {
    image: SeDisabled,
  },
  nz: {
    image: NzDisabled,
  },
  ie: {
    image: IeDisabled,
  },
  fi: {
    image: FiDisabled,
  },
  no: {
    image: NoDisabled,
  },
};

const DisabledCountryContent = ({ handleClick, intl: { formatMessage } }) => {
  const history = useHistory();
  const countryModalDisabled = useSelector(({ app }) => app.countryModalDisabled);
  const code = useSelector(({ app }) => app.currentCountry && app.currentCountry.code.toLowerCase()) || 'us';

  if (typeof countryModalDisabled !== 'object' && !CODE_CONTENT[code]) {
    return null;
  }

  return (
    <div className={style.wrapper}>
      <ReactSVG src={CODE_CONTENT[typeof countryModalDisabled === 'object' ? countryModalDisabled.code : code].image} />
      <p>
        {formatMessage(messages['The {name} contest has been temporarily closed'], {
          name: formatMessage(messages[COUNTRIES_MAP[
            typeof countryModalDisabled === 'object' ? countryModalDisabled.code : code
          ]]),
        })}
      </p>
      <span>{formatMessage({ defaultMessage: 'Please check out the Weekly Challenges', id: 'Please check out the Weekly Challenges' })}</span>
      <Button
        onClick={() => {
          if (handleClick) {
            handleClick();
          } else {
            history.push(`/${code}/challenges/current`);
          }
        }}
        className="big primary"
      >
        {formatMessage({ defaultMessage: 'Weekly Challenge', id: 'wk.weeklyChallenges' })}
      </Button>
    </div>
  );
};

export default injectIntl(DisabledCountryContent);
