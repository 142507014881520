import { LOADING_STATES } from 'src/utils/maps';
import { TOURNAMENT_GAME_CONSTANTS } from 'src/modules/tournaments/constants/tournament-game';
import { newState } from 'src/utils';

const initialState = {
  data: [],
  meta: {
    closestWheel: 0,
    closestAd: 0,
    slidesLeft: 0,
    progress: 0,
  },
  status: LOADING_STATES.LOADING,
  error: null,
  isMore: true,
};

const tournamentGameReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case TOURNAMENT_GAME_CONSTANTS.setData:
      return newState(state, {
        data: action.payload,
      });
    case TOURNAMENT_GAME_CONSTANTS.setStatus:
      return newState(state, {
        status: action.payload,
      });
    case TOURNAMENT_GAME_CONSTANTS.updateMeta:
      return newState(state, {
        meta: {
          ...state.meta,
          ...action.payload,
        },
      });
    case TOURNAMENT_GAME_CONSTANTS.setError:
      return newState(state, {
        error: action.payload,
      });
    case TOURNAMENT_GAME_CONSTANTS.setIsMore:
      return newState(state, {
        isMore: action.payload,
      });
    case TOURNAMENT_GAME_CONSTANTS.filterById:
      return newState(state, {
        data: state.data.filter((item) => item.slideId !== action.payload),
      });
    case TOURNAMENT_GAME_CONSTANTS.reset:
      return initialState;
    default:
      return state;
  }
};

export default tournamentGameReducer;
