import { LOADING_STATES } from 'src/utils/maps';
import { WITHDRAW_CONSTANTS } from 'src/constants/withdraw';
import { newState } from 'src/utils';

const initialState = {
  totalBalance: '$500.00',
  available: '$300.00',
  totalWinnings: '$300.00',
  totalWinningsVotes: '18 300',
  data: [],
  meta: {
    total: 0,
    page: 0,
    perPage: 10,
    next: null,
  },
  status: LOADING_STATES.LOADING,
  isFirstLoad: true,
  error: null,
};

const withdrawReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case WITHDRAW_CONSTANTS.SET_TOTAL_BALANCE:
      return newState(state, {
        totalBalance: action.payload,
      });
    case WITHDRAW_CONSTANTS.SET_AVAILABLE:
      return newState(state, {
        available: action.payload,
      });
    case WITHDRAW_CONSTANTS.SET_TOTAL_WINNINGS:
      return newState(state, {
        totalWinnings: action.payload,
      });
    case WITHDRAW_CONSTANTS.SET_DATA:
      return newState(state, {
        data: action.payload,
      });
    case WITHDRAW_CONSTANTS.UPDATE_META:
      return newState(state, {
        meta: {
          ...state.meta,
          ...action.payload,
        },
      });
    case WITHDRAW_CONSTANTS.SET_STATUS:
      return newState(state, {
        status: action.payload,
      });
    case WITHDRAW_CONSTANTS.SET_IS_FIRST_LOAD:
      return newState(state, {
        isFirstLoad: action.payload,
      });
    case WITHDRAW_CONSTANTS.SET_ERROR:
      return newState(state, {
        error: action.payload,
      });
    default:
      return state;
  }
};

export default withdrawReducer;
