import { proxyHandler } from 'src/utils/redux';

const prefix = '@user';

const constants = {
  SET_WALLET: 'setWallet',
  UPDATE: 'update',
};

export const USER_CONSTANTS = new Proxy(constants, proxyHandler(prefix));
